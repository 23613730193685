import React from 'react';
import { render } from 'react-dom';
import App from './scripts/components/app.jsx';
import Scene from './scripts/constants/scene-constants.jsx';
import SceneAction from './scripts/actions/scene-action.jsx';
import LoginAction from './scripts/actions/login-action.jsx';

render(
  <App />,
  document.getElementById('root')
);

(function SimpleRouting(){
  if(/^#!\/success$/.test(window.location.hash)){
    window.location.hash = '#!/';
    SceneAction.changeScene(Scene.Login);
    LoginAction.tryLogin();
    return;
  }

  if(/^#!\/failure/.test(window.location.hash)){
    window.location.hash = '#!/';
    LoginAction.throwLoginError();
    SceneAction.changeScene(Scene.Login);
    return;
  }

  SceneAction.changeScene(Scene.Login);
  window.location.hash = '#!/';
})();
