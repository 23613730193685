import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

//
// チームラボのロゴ
// 大きさはprops.sizeで決める
//
class TeamLab extends React.Component{

  static get Normal(){ return 'normal'; }
  static get Small(){ return 'small'; }

  constructor(){
    super();

    this.state = {
      rotation: 0
    };

    this.isRotating = false;
    this.velocity = 0;

    this.resistance = 0.96;

    this.loop = this.loop.bind(this);
  }

  render(){
    return (
      <div
        className={`u-teamlab u-teamlab--${this.props.size}`}
        {...this.props}
      />
    );
  }

  loop(){
    let newRotation = this.state.rotation + this.velocity;
    newRotation > 360 && (newRotation -= 360);
    newRotation < 0 && (newRotation += 360);
    this.setState({
      rotation: newRotation
    });

    if(this.velocity > 20){
      this.velocity *= this.resistance;
    } else if(this.velocity >= 3){
      this.velocity *= 0.98;
    }

    if(this.velocity < 3 && (newRotation < 3 || newRotation > 357)){
      this.setState({
        rotation: 0
      });
      this.velocity = 0;
    }

    if(this.velocity < 0.1){
      this.isRotating = false;
    }

    requestAnimationFrame(this.loop);
  }

}

TeamLab.propTypes = {
  size: PropTypes.string.isRequired
};

export default TeamLab;
