import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// TODO 表示する顔写真までのパスはpropsで与えるようにする

//
// 顔のアイコン
//
class Face extends React.Component{

  static get List(){ return 'list'; }
  static get Message(){ return 'message'; }

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.isEnabled = this.props.isEnabled;

    // props.delayに数値が入っている場ReactDOM合は{delay}ms後に表示させるので、値があればfalseを入れておく
    this.state = {
      show: !this.props.delay
    };

    // 表示されるまでにunmountされる場合もあるのでsetTimeoutのidをキャッシュしておいてclearTimeoutできるようにする
    this.timer = null;

    // アイコンのサイズはprops.sizeで受け取る
    this.styles = {
      faceImg: {
        backgroundImage: `url(/images/${this.props.photo ? `face/${ this.props.photo}` : 'dummy_face.png'})`
      }
    };
  }

  // props.delayが設定されていれば{props.delay}ms後に表示する
  componentWillMount(){
    if(this.props.delay){
      this.timer = setTimeout(() => {
        this.timer = null;

        this.setState({
          show: true
        });
      }, this.props.delay);
    }
  }

  // {props.delay}ms経つ前にunmountする場合はタイマーを消しておく
  componentWillUnmount(){
    this.timer && clearTimeout(this.timer);
  }

  render(){

    // レンダリングのあと一回だけクリック可能にする場合はフラグをリセットする
    this.__clickedAfterRender = false;

    let addClassName = '';
    this.props.size && (addClassName += ` i-face--size-${this.props.size}`);
    this.props.design && (addClassName += ` i-face--${this.props.design}`);

    // 表示するまでのディレイに合わせてi-face--showクラスを付与していく
    if(this.state.show){
      addClassName += ' i-face--show';
    }

    return (
      <div className={`i-face${addClassName}`} >
        { typeof this.props.photo === 'object' && <div className="i-face__image" style={this.props.photo} onClick={this.handleClick}></div> }
        <div className="i-face__name">{this.props.name}</div>
        <div className="i-face__team">{this.props.team}</div>
        <div className="i-face__team">{this.props.role}</div>
      </div>
    );

  }

  handleClick(event){
    event.preventDefault();

    // レンダリングのあと一回だけクリック可能にする場合はフラグをチェックする
    if(!this.isEnabled || this.props.once && this.__clickedAfterRender){
      return;
    }

    // レンダリングのあと一回だけクリック可能にする場合はフラグを立てる
    this.__clickedAfterRender = true;
    _.isFunction(this.props.handleClick) && this.props.handleClick(event);
  }

  enable(){
    this.isEnabled = true;
  }

  disable(){
    this.isEnabled = false;
  }

}

Face.propTypes = {
  size: PropTypes.string,
  isEnabled: PropTypes.bool,
  once: PropTypes.bool,
  design: PropTypes.string,
  delay: PropTypes.number,
  name: PropTypes.string.isRequired,
  team: PropTypes.string.isRequired,
  photo: PropTypes.object
};
Face.defaultProps = {
  size: 'default',
  isEnabled: true,
  once: true,
  design: 'default',
  delay: 0,
  photo: {}
};

export default Face;
