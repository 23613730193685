import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

//
// ボタン
// 見た目はprops.designでクラスを渡して決める
//
class Button extends React.Component{

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.isEnabled = this.props.isEnabled;
  }

  render(){

    // レンダリングのあと一回だけクリック可能にする場合はフラグをリセットする
    this.__clickedAfterRender = false;

    let className = `u-button u-button--${this.props.design}`;
    this.props.className && (className += ` ${this.props.className}`);

    return (
      <div className={className}>
        <a href="#" onClick={this.handleClick}>{this.props.title}</a>
      </div>
    );

  }

  handleClick(event){
    event.preventDefault();

    if(this.props.isRequesting){
      return;
    }

    // レンダリングのあと一回だけクリック可能にする場合はフラグを立てる
    this.__clickedAfterRender = true;
    _.isFunction(this.props.handleClick) && this.props.handleClick(event);
  }

  enable(){
    this.isEnabled = true;
  }

  disable(){
    this.isEnabled = false;
  }

}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  design: PropTypes.string,
  isEnabled: PropTypes.bool,
  once: PropTypes.bool
};
Button.defaultProps = {
  design: 'default',
  isEnabled: true,
  once: false
};

export default Button;
