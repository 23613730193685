import EventEmitter from 'events';
import BaseStore from './base-store.jsx';
import ActionType from '../constants/action-type-constants.jsx';
import ErrorType from '../constants/error-type-constants.jsx';

//
// エラー情報のstore
//
class ErrorStore extends BaseStore{

  constructor(){
    super();

    this.ChangeEvent = 'change-error';

    this.__errors = [];
  }

  __onChange(action){

    switch (action.actionType){
      case ActionType.Error:
        const error = {
          type: action.type,
          message: action.message,
          closable: action.closable
        };

        // エラー情報をキャッシュする
        this.__errors.push(error);

        // イベントを発行する。引数にエラー情報を渡す
        this.emitChange(this.ChangeEvent, error);
        break;
      default:
    }

  }

  // 最新のエラーを取得する
  getLatestError(){
    return this.__errors.length ? this.__errors[this.__errors.length - 1] : null;
  }

  // すべてのエラーを取得する
  getErrors(){
    return this.__errors;
  }

}


export default new ErrorStore();
