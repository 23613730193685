import EventEmitter from 'events';
import BaseStore from './base-store.jsx';
import ActionType from '../constants/action-type-constants.jsx';

//
// メッセージフレームの表示のstore
//
class MessageFrameStore extends BaseStore{

  constructor(){
    super();

    this.ChangeEvent = 'change-message-frame';

    this.__isShow = false;
  }

  __onChange(action){

    switch (action.actionType){
      case ActionType.ShowMessageFrame:
        this.__isShow = action.isShow;
        this.emitChange(this.ChangeEvent);
        break;
      default:
    }

  }

  isShow(){
    return this.__isShow;
  }

}


export default new MessageFrameStore();
