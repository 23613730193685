//
// アクションの種類
//
export default {
  SceneChange: 'scene-change',
  Login: 'login',
  MessageLoaded: 'message-loaded',
  YearsLoaded: 'years-loaded',
  ShowMessage: 'show-message',
  ShowFooter: 'show-footer',
  ShowMessageFrame: 'show-message-frame',
  Error: 'error',
  Rotate: 'rotate'
};
