import React from 'react';
import MessageFrameStore  from '../../stores/message-frame-store.jsx';
import MessageListStore from '../../stores/message-list-store.jsx';
import MessageStore from '../../stores/message-store.jsx';
import SceneStore from '../../stores/scene-store.jsx';
import SceneAction from '../../actions/scene-action.jsx';
import Scene from '../../constants/scene-constants.jsx';
import Constants from '../../constants/common-constants.jsx';

//
// メッセージの外枠
//
class MessageFrame extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      isShow: MessageFrameStore.isShow(),
      leaving: false,
      messageAmount: 0,
      currentMessageNumber: 0
    };

    this.toggleShow = this.toggleShow.bind(this);
    this.onGetMessage = this.onGetMessage.bind(this);
    this.onSelectMessage = this.onSelectMessage.bind(this);
  }

  componentDidMount(){
    MessageListStore.addChangeListener(this.onGetMessage);
    MessageStore.addChangeListener(this.onSelectMessage);
    MessageFrameStore.addChangeListener(this.toggleShow);
  }

  render(){
    if(!this.state.isShow){
      return null;
    }

    let closeClass = '';
    this.state.leaving && (closeClass += ' close');

    return (
      <div className="message-frame">
        <div className={`message-frame__border message-frame__border--top${ closeClass}`}></div>
        <div className={`message-frame__border message-frame__border--left${ closeClass}`}></div>
        <div className={`message-frame__border message-frame__border--right${ closeClass}`}></div>
        <div className={`message-frame__border message-frame__border--bottom${ closeClass}`}></div>
        <div className={`message-frame__navigator${ closeClass}`} onClick={this.handleClick}>
          <span className="current">{this.state.currentMessageNumber + 1}</span>
          <span className="total"> / {this.state.messageAmount}</span>
        </div>
      </div>
    );
  }

  handleClick(){
    if(+new Date() - SceneStore.getLastChangeTime() > Constants.SectionChangeInterval){
      SceneAction.changeScene(Scene.MessageList);
    }
  }

  onGetMessage(){
    this.setState({
      messageAmount: MessageListStore.getMessageAmount()
    });
  }

  onSelectMessage(){
    const messageNumber = MessageStore.getMessageNumber();
    this.setState({
      currentMessageNumber: messageNumber
    });
  }

  // 表示を切り替える
  toggleShow(){
    if(MessageFrameStore.isShow()){
      this.setState({
        isShow: true
      });
      return;
    }

    this.setState({
      leaving: true
    });
    setTimeout(() => {
      this.setState({
        isShow: false,
        leaving: false
      });
    }, 500);
  }

}

export default MessageFrame;
