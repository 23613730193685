import Dispatcher from '../dispatcher.jsx';
import ActionType from '../constants/action-type-constants.jsx';

//
// シーン関係のアクション
//
class SceneAction{

  // シーンを変更する
  changeScene(newScene){
    Dispatcher.dispatch({
      actionType: ActionType.SceneChange,
      newScene
    });
  }

}

export default new SceneAction();
