import EventEmitter from 'events';
import BaseStore from './base-store.jsx';
import ActionType from '../constants/action-type-constants.jsx';

class UserStore extends BaseStore{

  constructor(){
    super();

    this.ChangeEvent = 'change-login';

    this.__isLogin = false;
    this.__account = '__account__';
    this.__photo = '__photo__';
    this.__name = '__name__';
    this.__team = '__team__';
  }

  __onChange(action){

    switch (action.actionType){
      case ActionType.Login:
        this.__isLogin = action.success;
        if(action.success){
          this.__name = action.name;
          this.__team = action.team;
          this.__account = action.account;
          this.__photo = action.photo;
        }
        this.emitChange(this.ChangeEvent);
        break;
      default:
    }

  }

  isLogin(){
    return this.__isLogin;
  }

  getName(){
    return this.__name;
  }

  getTeam(){
    return this.__team;
  }

  getPhoto(){
    return this.__photo;
  }

  getAccount(){
    return this.__account;
  }

}


export default new UserStore();
