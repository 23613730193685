import Dispatcher from '../dispatcher.jsx';
import Constants from '../constants/common-constants.jsx';
import ActionType from '../constants/action-type-constants.jsx';
import ErrorType from '../constants/error-type-constants.jsx';
import request from 'superagent';
import nocache from 'superagent-no-cache';

//
// ログイン関係のアクション
//
class LoginAction {

  // ログインできているかリクエストする
  tryLogin() {
    return new Promise((resolve, reject) => {
      request
        .get(Constants.RequestUrl.Login)
        .use(nocache)
        .end((err, res) => {
          if (err) {
            this.throwRequestError();
            reject(ErrorType.LoginRequest);
            return;
          }

          // ログインできていない場合は認証画面にリダイレクトする
          if (!(res && res.body && res.body.result)) {
            if (res && res.body) {
              // team-labアカウント以外にログインしている場合はgoogleのログアウト画面に飛ばす
              if (res.body.logged) {
                this.throwNotTeamlabLoginError();
                reject(ErrorType.NotTeamlabLogin);
                return;
              }

              // redirect to login if they aren't logged in
              if (res.body.message === 'You are not authenticated.') {
                window.location.href = Constants.LoginUrl
                resolve();
                return;
              }

              if (res.body.message === 'Data not found.') {
                this.throwUserError();
                reject(ErrorType.UserError);
                return;
              }
            }

            console.log(res?.body);
            this.throwRequestError();
            reject(ErrorType.throwRequestError);
            return;
          }

          Dispatcher.dispatch({
            actionType: ActionType.Login,
            success: true,
            account: res.body.result.email,
            photo: res.body.result.photo,
            name: res.body.result.name,
            team: res.body.result.team || ''
          });
          resolve();
        });
    });
  }

  // リクエスト自体が失敗した場合の処理
  throwRequestError() {
    Dispatcher.dispatch({
      actionType: ActionType.Error,
      type: ErrorType.LoginRequest,
      message: Constants.ErrorMessage.Request,
      closable: true
    });
  }

  // 認証ができていない場合の処理
  throwLoginError() {
    Dispatcher.dispatch({
      actionType: ActionType.Error,
      type: ErrorType.LoginRequest,
      message: Constants.ErrorMessage.LoginFailure,
      closable: true
    });
  }

  // チームラボ以外のアカウントで認証している場合の処理
  throwNotTeamlabLoginError() {
    Dispatcher.dispatch({
      actionType: ActionType.Error,
      type: ErrorType.NotTeamlabLogin,
      message: Constants.ErrorMessage.NotTeamlabLogin,
      closable: false
    });
  }

  thorwUserError() {
    Dispatcher.dispatch({
      actionType: ActionType.Error,
      type: ErrorType.UserError,
      message: Constants.ErrorMessage.UserError,
      closable: true
    });
  }

}

export default new LoginAction();
