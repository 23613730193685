import React from 'react';
import ReactDom from 'react-dom';
import BaseScene from './base-scene.jsx';
import MyFace from '../items/my-face.jsx';
import FillOut from '../items/fill-out.jsx';
import SceneStore from '../../stores/scene-store.jsx';
import MessageListStore from '../../stores/message-list-store.jsx';
import Scene from '../../constants/scene-constants.jsx';
import SceneAction from '../../actions/scene-action.jsx';
import MessageAction from '../../actions/message-action.jsx';

//
// ローディング画面
//
class LoadingScene extends React.Component{

  constructor(props){
    super(props);

    this.onMessageLoaded = this.onMessageLoaded.bind(this);
  }

  componentDidMount(){
    MessageListStore.addChangeListener(this.onMessageLoaded);
  }

  render(){
    return (
      <BaseScene enterDelay={this.enterDelay} beforeEnter={this.beforeEnter} left={this.left} name={Scene.Loading} {...this.props}>
        <div className="centerize">
          <MyFace ref="myFace" size={MyFace.Loading} isEnabled={false} design="loading" />
          <FillOut ref="fillOut" />
        </div>
      </BaseScene>
    );
  }

  // ログイン画面から切り替わった場合は少しタイミングをずらす
  enterDelay(newScene, oldScene){
    if(oldScene === Scene.Login){
      return 250;
    }
    return 0;
  }

  // この画面に切り替わった時にメッセージをリクエストし始める
  beforeEnter(){
    const currentYear = new Date().getFullYear();
    MessageAction.loadMessages(currentYear);
    setTimeout(() => MessageAction.getAvailableYears(), 500);
  }

  left(){
    (document.body || document.documentElement).className += ' message';
  }

  // メッセージを受け取ったらメッセージ一覧画面に遷移する
  onMessageLoaded(){
    if(SceneStore.getCurrentScene() === Scene.Loading){
      setTimeout(() => SceneAction.changeScene(Scene.MessageList), 0);
    }
  }

}

export default LoadingScene;
