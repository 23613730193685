/* eslint-disable max-len */
import React from 'react';

//
// 塗りつぶしのパス
// アニメーションは親要素に.scene__hoge--leaveがつくので、それを使ってCSSでアニメーションさせる
//
class FillOut extends React.Component{

  render(){

    return (
      <svg className="fill-out" xmlns="http://www.w3.org/2000/svg" width="375.53" height="405.531" viewBox="0 0 375.53 405.531">
        <path ref="path" d="M2510.28,321.383h-16.66c-26.41,26.408-59.87,45.443-83.98,69.555q34.29-16.624,68.59-33.25c19.1-11.462,39.54-29.165,63.02-29.165-4.89,9.772-34.4,24.138-43.66,31.086q-30.21,22.913-60.42,45.829-18.615,11.721-37.25,23.445c16.03,0,57.56-20.6,74.83-27.078,23.95-8.98,63.39-27.086,89.17-27.086,12.04-8.018,24.73-14.452,37.5-20.836,0,4.564-37.28,28.04-41.5,31.086-35.3,25.493-72.41,47.569-108.33,71.523q-19.755,12.5-39.5,25,71.775-40.578,143.58-81.164c24.54-14.565,82.77-47.219,113.03-47.219,0,6.939-35.33,31.079-35.33,31.079-34.85,29.492-66.93,62.979-104.17,89.585q-18.75,12.5-37.5,25c-8.09,6.062-54.75,43.75-64.5,43.75q18.705-10.416,37.42-20.835l137.5-68.75,67.36-29.165c0,6.263-46.23,32.607-49.84,35.165-37.18,26.335-73.56,53.612-111.8,78.476q-20.79,13.5-41.59,27c0-7.674,33.34-16.586,33.34-16.586,27.96-8.9,56.53-12.385,85.41-16.664,8.18-1.212,51.45-6.945,49.31-6.945-13.93,0-23.02,8.654-35.42,14.586q-43.74,19.787-87.5,39.578c-13.63,6.521-68.42,28.39-63.8,28.39q21.87-8.331,43.75-16.664,41.655-9.374,83.33-18.75,22.575-7.64,45.14-15.281c-63.26,21.075-125.35,61.556-179.17,100l-72.91,52.086q-14.58,9.374-29.17,18.75c18.08-18.092,2.58-4.783,31.25-20.836,63.22-35.4,128.81-54.925,195.83-81.25q41.58-19.747,83.16-39.5c-29.45,0-94.64,41.517-122.66,58.336-7.27,4.364-15.83,12.5-24.91,12.5l33.16-8.336c45.25-11.8,87.46-27.823,131.25-43.75,15.23-5.537,31.88-13.89,48.45-13.89q-23.925,9.334-47.84,18.671c-41.65,17.854-79.12,40.937-118.75,62.5-14.37,7.818-40.21,25.7-57.63,25.7v2q17.655-6.25,35.33-12.5c27.19-10.687,55.49-20.836,85.17-20.836" transform="translate(-2338.56 -321.375)"/>
      </svg>
    );

  }

}

export default FillOut;
