import EventEmitter from 'events';
import BaseStore from './base-store.jsx';
import ActionType from '../constants/action-type-constants.jsx';

//
// フッターの表示のstore
//
class FooterStore extends BaseStore{

  constructor(){
    super();

    this.ChangeEvent = 'change-footer';

    this.__isShow = false;
  }

  __onChange(action){

    switch (action.actionType){
      case ActionType.ShowFooter:
        this.__isShow = action.isShow;
        this.emitChange(this.ChangeEvent);
        break;
      default:
    }

  }

  isShow(){
    return this.__isShow;
  }

}


export default new FooterStore();
