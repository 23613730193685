import EventEmitter from 'events';
import BaseStore from './base-store.jsx';
import ActionType from '../constants/action-type-constants.jsx';
import _ from 'lodash';

//
// メッセージ情報のstore
//
class MessageListStore extends BaseStore{

  constructor(){
    super();

    this.MessageChangeEvent = 'change-message-list';
    this.YearChangeEvent = 'change-years';

    this.__messages = [];
    this.__years = [];
  }

  __onChange(action){
    switch (action.actionType){
      case ActionType.MessageLoaded:
        this.__messages = action.messages;
        this.emitChange(this.MessageChangeEvent);
        break;
      case ActionType.YearsLoaded:
        this.__years = action.years;
        this.emitChange(this.YearChangeEvent);
        break;
      default:
    }

  }

  getMessages(){
    return this.__messages;
  }

  getYears(){
    return this.__years;
  }

  getMessageAmount(){
    return this.__messages.length;
  }

  getMessage(account){
    return _.find(this.getMessages(), { account });
  }

  getMessageNumber(account){
    return _.findIndex(this.getMessages(), { account }) + 1;
  }

}


export default new MessageListStore();
