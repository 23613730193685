import React from 'react';
import BaseScene from './base-scene.jsx';
import Button from '../utils/button.jsx';
import TeamLab from '../utils/team-lab.jsx';
import UserStore from '../../stores/user-store.jsx';
import SceneStore from '../../stores/scene-store.jsx';
import Scene from '../../constants/scene-constants.jsx';
import LoginAction from '../../actions/login-action.jsx';
import SceneAction from '../../actions/scene-action.jsx';
import Constants from '../../constants/common-constants.jsx';

//
// ログイン画面
//
class LoginScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isRequesting: false
    };

    this.onResultLogin = this.onResultLogin.bind(this);
    this.entered = this.entered.bind(this);
    this.tryLogin = this.tryLogin.bind(this);
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onResultLogin);
  }

  render() {
    return (
      <BaseScene entered={this.entered} name={Scene.Login} {...this.props}>
        <TeamLab size={TeamLab.Normal} />
        <div className="centerize">
          <div className="title">TEAMLAB MESSAGE BOOK</div>
          <Button ref="button" design="login" title="LOGIN" isEnabled={false} handleClick={this.tryLogin} isRequesting={this.state.isRequesting} />
        </div>
        <div className="version-title">{Constants.VersionTitle}</div>
      </BaseScene>
    );
  }

  // ボタンを押したらログインのリクエストを行う
  async tryLogin() {
    this.state.isRequesting = true;
    try {
      await LoginAction.tryLogin();
    } catch (err) {
      // TODO: find out why this isn't printing anything
    } finally {
      this.state.isRequesting = false;
    }

  }

  // ログイン済みのレスポンスを受け取ったらローディング画面に以降する
  onResultLogin() {
    if (UserStore.isLogin() && SceneStore.getCurrentScene() === Scene.Login) {
      setTimeout(() => SceneAction.changeScene(Scene.Loading), 0);
    }
  }

  // 画面に切り替わり終わってからボタンを活性化する
  entered() {
    this.refs.button.enable();
  }

}

export default LoginScene;
