import React from 'react';
import Footer from './parts/footer.jsx';
import MessageFrame from './parts/message-frame.jsx';
import LoginScene from './scenes/login-scene.jsx';
import LoadingScene from './scenes/loading-scene.jsx';
import MessageScene from './scenes/message-scene.jsx';
import MessageListScene from './scenes/message-list-scene.jsx';
import Error from './items/error.jsx';
import SceneStore from '../stores/scene-store.jsx';
import PartsAction from '../actions/parts-action.jsx';
import Scene from '../constants/scene-constants.jsx';
import _ from 'lodash';
import '../../styles/main.scss';

//
// メインのコンポーネント
//
class App extends React.Component{

  constructor(){
    super();

    this.__hasFooter = [Scene.MessageList, Scene.Message];
    this.__hasMessageFrame = [Scene.Message];

    this.onSceneChange = this.onSceneChange.bind(this);
  }

  componentDidMount(){
    SceneStore.addChangeListener(this.onSceneChange);
  }

  render(){
    return (
      <div className="scene__wrapper">
        <LoginScene />
        <LoadingScene />
        <MessageScene />
        <MessageListScene />
        <MessageFrame />
        <Footer />
        <Error />
      </div>
    );
  }

  onSceneChange(){
    const newScene = SceneStore.getNewScene();

    setTimeout(() => {
      _.includes(this.__hasFooter, newScene) ? PartsAction.showFooter() : PartsAction.hideFooter();
      _.includes(this.__hasMessageFrame, newScene) ? PartsAction.showMessageFrame() : PartsAction.hideMessageFrame();
    }, 0);
  }

}

export default App;
