/* eslint-disable no-empty-function */
import Dispatcher from '../dispatcher.jsx';
import EventEmitter from 'events';

// var dispatchTokens = [];
//
// storeの基底クラス
//
class BaseStore extends EventEmitter{

  constructor(){
    super();

    this.__onChange = this.__onChange.bind(this);

    // Dispatcher.waitForのためにトークンを保持しておく
    // が、Dispatcher.waitForの使い方が分からないのでsetTimeoutでごまかした。。。
    // this.dispatchToken = Dispatcher.register(this.__onChange);
    // this.dispatchTokens = dispatchTokens;
    // this.dispatchTokens.push(this.dispatchToken);
    Dispatcher.register(this.__onChange);

    // イベント判別用文字列。継承先でユニークな文字列に置き換える
    this.ChangeEvent = 'change';
  }

  __onChange(action){}

  emitChange(args){
    this.emit(this.ChangeEvent, args);
  }

  addChangeListener(callback){
    this.on(this.ChangeEvent, callback);
  }

  removeChangeListener(callback){
    this.removeListener(this.ChangeEvent, callback);
  }

}


export default BaseStore;
