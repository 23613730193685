import React from 'react';
import Face from './face.jsx';
import UserStore from '../../stores/user-store.jsx';

//
// 自分の顔のアイコン
//
class MyFace extends React.Component {

  static get Loading() { return 'loading'; }
  static get Footer() { return 'footer'; }

  constructor(props) {
    super(props);

    this.state = {
      faceLoaded: false
    };

    this.onResultLogin = this.onResultLogin.bind(this);
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onResultLogin);
  }

  render() {

    return (
      <Face ref="face" key={this.state.faceLoaded} photo={this.getImage(UserStore.getPhoto())} account={UserStore.getAccount()} name={UserStore.getName()} team={UserStore.getTeam()} {...this.props} />
    );

  }

  // ログインが成功したときにFaceを更新する
  // UserStoreから名前とチームを取得するためにstateとkeyを利用している(ES6ではsetPropsが使えないらしい)
  onResultLogin() {
    if (UserStore.isLogin()) {
      this.setState({
        faceLoaded: true
      });
    }
  }

  getImage(image) {
    return {
      backgroundImage: `url(/images/${image ? `face/${image}` : 'dummy_face.png'})`
    }
  }

}

export default MyFace;
