import EventEmitter from 'events';
import BaseStore from './base-store.jsx';
import Scene from '../constants/scene-constants.jsx';
import ActionType from '../constants/action-type-constants.jsx';

class SceneStore extends BaseStore{

  constructor(){
    super();

    this.ChangeEvent = 'change-scene';

    this.__oldScene = Scene.None;
    this.__newScene = Scene.None;

    this.__lastChangeTimer = 0;
  }

  __onChange(action){

    switch (action.actionType){
      case ActionType.SceneChange:
        if(action.newScene === this.__newScene){
          return;
        }

        this.__lastChangeTimer = +new Date();

        this.__oldScene = this.__newScene;
        this.__newScene = action.newScene;
        this.emitChange(this.ChangeEvent);
        break;
      default:
    }

  }

  getOldScene(){
    return this.__oldScene;
  }

  getNewScene(){
    return this.__newScene;
  }

  getCurrentScene(){
    return this.__newScene;
  }

  getLastChangeTime(){
    return this.__lastChangeTimer;
  }

}

export default new SceneStore();
