//
// エラーの種類
//
export default {
  LoginRequest: 'login-request',
  NotTeamlabLogin: 'not-teamlab-login',
  NoMessages: 'no-messages',
  UserError: 'user-error',
  Unknown: 'unknown',
};
