import React from 'react';
import BaseScene from './base-scene.jsx';
import Face from '../items/face.jsx';
import Scene from '../../constants/scene-constants.jsx';
import SceneAction from '../../actions/scene-action.jsx';
import MessageListStore from '../../stores/message-list-store.jsx';
import MessageStore from '../../stores/message-store.jsx';
import SceneStore from '../../stores/scene-store.jsx';
import MessageAction from '../../actions/message-action.jsx';
import Constants from '../../constants/common-constants.jsx';

//
// メッセージ画面
//
class MessageScene extends React.Component {

  constructor(props) {
    super(props);

    // リスト画面から飛んだ時に表示するメッセージの番号
    // スクロール位置をこのメッセージまで飛ばす必要があるので保持しておく
    this.messageNumber = 0;
    // スクロールさせる際にずらすyの量
    this.jumpOffsetY = -80;

    this.state = {
      messages: [],
      enter: false,
      leave: false
    };

    this.$messages = [];

    this.enter = this.enter.bind(this);
    this.entered = this.entered.bind(this);
    this.leave = this.leave.bind(this);
    this.left = this.left.bind(this);
    this.entering = this.entering.bind(this);
    this.onMessageShow = this.onMessageShow.bind(this);
    this.onMessageLoaded = this.onMessageLoaded.bind(this);

    window.addEventListener('scroll', (event) => {
      if (SceneStore.getCurrentScene() !== Scene.Message) {
        return;
      }

      const messageNumber = this.getMessageNumberByScroll(document.body.scrollTop || document.documentElement.scrollTop);
      MessageAction.showMessage(messageNumber);
    });
  }

  componentDidMount() {
    MessageStore.addChangeListener(this.onMessageShow);
    MessageListStore.addChangeListener(this.onMessageLoaded);
  }

  render() {
    const Messages = this.state.messages.map((message, idx) => {

      // すべてのメッセージにアニメーションクラスを付与するとスマホで重たいので
      // 今見ているメッセージとその前後のメッセージだけアニメーションさせる
      let addClass = '';
      if ((this.state.enter || this.state.leave) && Math.abs(this.messageNumber - idx) < 3) {
        this.state.enter && (addClass = ' enter');
        this.state.leave && (addClass = ' leave');
      }
      return (
        <div key={idx} id={this.getId(idx)} className={`message__wrapper${addClass}`}>
          {idx > 0 ? <div className="partition"></div> : null}
          <p className="message">{message.message}</p>
          <Face
            size={Face.Message}
            design="message"
            name={message.name}
            team={message.team}
            photo={this.getImage(message.photo)}
            account={message.messageFrom}
            delay={500}
          />
        </div>
      );
    });

    return (
      <BaseScene
        enterDelay={800}
        beforeEnter={this.enter}
        entered={this.entered}
        beforeLeave={this.leave}
        left={this.left}
        entering={this.entering}
        leaveDuration={500}
        name={Scene.Message}
        {...this.props}
      >
        {Messages}
      </BaseScene>
    );
  }

  enter() {
    this.setState({
      enter: true
    });
  }
  entered() {
    this.setState({
      enter: false
    });
  }

  leave() {
    this.setState({
      leave: true
    });
  }
  left() {
    this.setState({
      leave: false
    });
    // window.scrollTo(0, 0);
  }

  // 表示するメッセージの位置までスクロールする
  entering() {
    this.$messages = this.state.messages.map((message, idx) => document.getElementById(this.getId(idx)));


    const $message = document.getElementById(this.getId(this.messageNumber));

    if ($message) {
      window.scrollTo(0, $message.offsetTop + this.jumpOffsetY);
    }
  }

  // メッセージをロードしたらすべて表示する
  onMessageLoaded() {
    this.setState({
      messages: MessageListStore.getMessages()
    });
  }

  // リスト画面で選んだメッセージの番号を保持する
  onMessageShow() {
    const messageNumber = MessageStore.getMessageNumber();

    this.messageNumber = messageNumber;
  }

  // メッセージ番号を元にidを作る
  getId(idx) {
    return `message-${idx}`;
  }

  getImage(image) {
    return {
      backgroundImage: `url(/images/${image ? `face/${ image}` : 'dummy_face.png'})`
    }
  }

  // スクロール量を元に今表示しているメッセージを選択する
  getMessageNumberByScroll(scrollTop) {
    for (let idx = 0; idx < this.$messages.length; idx++) {
      if (this.$messages[idx]) {
        if (this.$messages[idx].offsetTop + this.jumpOffsetY > scrollTop) {
          return idx > 0 ? idx - 1 : 0;
        }
      }
    }

    return scrollTop > 100 ? this.$messages.length - 1 : 0;
  }

}

export default MessageScene;
