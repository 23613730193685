import EventEmitter from 'events';
import BaseStore from './base-store.jsx';
import ActionType from '../constants/action-type-constants.jsx';

//
// どのメッセージを開いているかのstore
//
class MessageStore extends BaseStore{

  constructor(){
    super();

    this.ChangeEvent = 'change-message';

    this.__messageNumber = '';
  }

  __onChange(action){

    switch (action.actionType){
      case ActionType.ShowMessage:
        this.__messageNumber = action.messageNumber;
        this.emitChange(this.ChangeEvent);
        break;
      default:
    }

  }

  getMessageNumber(){
    return this.__messageNumber;
  }

}


export default new MessageStore();
