import React from 'react';
import MyFace from '../items/my-face.jsx';
import TeamLab from '../utils/team-lab.jsx';
import FooterStore from '../../stores/footer-store.jsx';
import SceneStore from '../../stores/scene-store.jsx';
import Scene from '../../constants/scene-constants.jsx';
import SceneAction from '../../actions/scene-action.jsx';
import Constants from '../../constants/common-constants.jsx';

//
// メッセージ画面のフッター
//
class Footer extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      isShow: FooterStore.isShow()
    };

    this.toggleShow = this.toggleShow.bind(this);
  }

  componentDidMount(){
    FooterStore.addChangeListener(this.toggleShow);
  }

  render(){
    if(!this.state.isShow){
      return null;
    }

    const currentYear = new Date().getFullYear();

    return (
      <div className="p-footer">
        <MyFace size={MyFace.Footer} design="footer" once={false} handleClick={this.handleClick} />
        <div className="p-footer__title">
          <div className="text">{currentYear} Message book</div>
          <TeamLab size={TeamLab.Small} />
        </div>
      </div>
    );
  }

  handleClick(){
    if(+new Date() - SceneStore.getLastChangeTime() > Constants.SectionChangeInterval){
      SceneAction.changeScene(Scene.MessageList);
    }
  }

  // 表示を切り替える
  toggleShow(){
    this.setState({
      isShow: FooterStore.isShow()
    });
  }

}

export default Footer;
