/* eslint-disable no-empty-function */
import React from 'react';
import SceneStore from '../../stores/scene-store.jsx';
import Scene from '../../constants/scene-constants.jsx';
import PropTypes from 'prop-types';
import _ from 'lodash';

//
// 画面の基底コンポーネント
// 各画面のラッパーとして読み込む
//
class BaseScene extends React.Component{

  constructor(props){
    super(props);

    // isShowはrenderするかどうか
    // isShownはtransition用に1フレームずらして追加する
    // isEnteringはenterDuration分追加する
    // isLeavingはenterDuration分追加する
    this.state = {
      isShow: false,
      isShown: false,
      isEntering: false,
      isLeaving: false
    };

    // 画面が切り替わる時にクラスを付与する時間や遅延
    // propsで数値、もしくは関数を受け取る
    this.enterDuration = _.isNumber(this.props.enterDuration) ? () => this.props.enterDuration : this.props.enterDuration;
    this.leaveDuration = _.isNumber(this.props.leaveDuration) ? () => this.props.leaveDuration : this.props.leaveDuration;
    this.enterDelay = _.isNumber(this.props.enterDelay) ? () => this.props.enterDelay : this.props.enterDelay;
    this.leaveDelay = _.isNumber(this.props.leaveDelay) ? () => this.props.leaveDelay : this.props.leaveDelay;

    this.onChangeScene = this.onChangeScene.bind(this);
  }

  componentDidMount(){
    SceneStore.addChangeListener(this.onChangeScene);
  }

  render(){
    // if (!this.state.isShow) {
    //  return null;
    // }

    const isShow = this.state.isShow ? ' scene--show' : '',
      isShown = this.state.isShown ? ' scene--shown' : '',
      isEntering = this.state.isEntering ? ' scene--enter' : '',
      isLeaving = this.state.isLeaving ? ' scene--leave' : '';
    return (
      <div className={`scene scene--${this.props.name}${isShow}${isShown}${isEntering}${isLeaving}`}>{this.props.children}</div>
    );
  }

  // 画面が切り替わるとき
  onChangeScene(){
    const oldScene = SceneStore.getOldScene(),
      newScene = SceneStore.getNewScene();

    // 古い画面が自分ならleave処理を行う
    if(oldScene === this.props.name){
      this.props.beforeLeave(newScene, oldScene);

      // 画面が切り替わる前にアニメーションをはさみたい場合などは遅延させる
      const leaveDelay = this.leaveDelay(newScene, oldScene);
      if(leaveDelay){
        setTimeout(() => { this.leave(newScene, oldScene); }, leaveDelay);
      } else {
        this.leave(newScene, oldScene);
      }
    }

    // 新しい画面が自分ならenter処理を行う
    if(newScene === this.props.name){
      this.props.beforeEnter(newScene, oldScene);

      // 古い画面が切り替わる際にアニメーションをはさみたい場合などは遅延させる
      const enterDelay = this.enterDelay(newScene, oldScene);
      if(enterDelay){
        setTimeout(() => { this.enter(newScene, oldScene); }, enterDelay);
      } else {
        this.enter(newScene, oldScene);
      }
    }
  }

  // この画面に切り替える
  enter(newScene, oldScene){
    this.setState({
      isEntering: true,
      isShow: true
    });
    requestAnimationFrame(() => {
      this.setState({
        isShown: true
      });
    });

    this.props.entering(newScene, oldScene);

    // enterDurationの間だけクラスを付与する
    setTimeout(() => {
      this.setState({
        isEntering: false
      });
      this.props.entered(newScene, oldScene);
    }, this.enterDuration(newScene, oldScene));
  }

  // この画面から切り替える
  leave(newScene, oldScene){
    this.setState({
      isLeaving: true
    });

    this.props.leaving(newScene, oldScene);

    // leaveDurationの間だけクラスを付与する
    setTimeout(() => {
      this.setState({
        isLeaving: false,
        isShow: false,
        isShown: false
      });
      this.props.left(newScene, oldScene);
    }, this.leaveDuration(newScene, oldScene));
  }

}

BaseScene.propTypes = {
  name: PropTypes.string,
  enterDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  leaveDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  enterDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  leaveDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  beforeEnter: PropTypes.func,
  beforeLeave: PropTypes.func,
  entering: PropTypes.func,
  entered: PropTypes.func,
  leaving: PropTypes.func,
  left: PropTypes.func
};
BaseScene.defaultProps = {
  enterDuration: 1000,
  leaveDuration: 1000,
  enterDelay: 0,
  leaveDelay: 0,
  beforeEnter: () => {},
  beforeLeave: () => {},
  entering: () => {},
  entered: () => {},
  leaving: () => {},
  left: () => {}
};

export default BaseScene;
