import React from 'react';
import ErrorStore from '../../stores/error-store.jsx';


// TODO: 閉じられるようにする

//
// エラー発生時のモーダル
//
class Error extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      isShow: false
    };

    this.onError = this.onError.bind(this);

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(){
    ErrorStore.addChangeListener(this.onError);
  }

  render(){
    if(!this.state.isShow){
      return <div />;
    }

    return (
      <div className="error">
        <p className="error__text" dangerouslySetInnerHTML={{ __html: `Error: ${ErrorStore.getLatestError().message}` }}></p>
        {ErrorStore.getLatestError().closable ? <p className="error__close" onClick={this.handleClick}>閉じる</p> : null}
      </div>
    );
  }

  handleClick(){
    this.setState({
      isShow: false
    });
  }

  // エラーが発生した時に表示させる
  onError(){
    this.setState({
      isShow: true
    });
  }

}

export default Error;
