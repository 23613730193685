import React, { useEffect, useState } from 'react';
import BaseScene from './base-scene.jsx';
import Face from '../items/face.jsx';
import MessageListStore from '../../stores/message-list-store.jsx';
import Scene from '../../constants/scene-constants.jsx';
import MessageAction from '../../actions/message-action.jsx';
import SceneAction from '../../actions/scene-action.jsx';

//
// メッセージ一覧画面
//
const MessageListScene = (props) => {

  const [faceList, setFaceList] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [currentlySelectedYear, setCurrentlySelectedYear] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // メッセージを受け取ったらメッセージ一覧を作成する
  const onMessageLoaded = () => {
    setFaceList(MessageListStore.getMessages());
    setIsLoading(false);
  };

  const onYearLoaded = () => {
    setAvailableYears(MessageListStore.getYears());
  };

  useEffect(() => {
    MessageListStore.addChangeListener(onMessageLoaded);
    MessageListStore.addChangeListener(onYearLoaded);
  }, []);

  // 顔をクリックしたとき、その人のメッセージを表示する
  const handleClick = () => {
    SceneAction.changeScene(Scene.Message);
  };
  const getImage = (image) => {
    return {
      backgroundImage: `url(/images/${image ? `face/${image}` : 'dummy_face.png'})`
    }
  }
  // 顔と名前の一覧
  const Faces = faceList.map((face, idx) => {
    const delay = 0;
    return (
      <Face
        key={idx}
        size={Face.List}
        design="list"
        once={false}
        handleClick={handleClick}
        name={face.name}
        team={face.team}
        role={face.role}
        account={face.account}
        photo={getImage(face.photo)}
        delay={delay}
      />
    );
  });

  const handleYearClick = (year) => {
    if (currentlySelectedYear === year || isLoading) return;
    setIsLoading(true);
    MessageAction.loadMessages(year);
    setCurrentlySelectedYear(year);
  };

  const yearElements = availableYears.map((year, i) => {
    return <h2
      className={`year ${year === currentlySelectedYear ? 'selected' : 'pointer'}`}
      key={i}
      onClick={() => handleYearClick(year)}
    >
      {year}
    </h2>;
  });

  //
  const close = () => {
    SceneAction.changeScene(Scene.Message);
  };

  // ローディング画面から遷移してくるときは、ローディング画面のアニメーションの分遅延させる
  const enterDelay = (newScene, oldScene) => {
    if (oldScene === Scene.Loading) {
      return 1000;
    }
    if (oldScene === Scene.Message) {
      return 550;
    }
    return 0;
  };

  return (
    <BaseScene enterDelay={enterDelay} name={Scene.MessageList} {...props}>
      <div className="years-container">
        {yearElements}
      </div>
      <div className="title">MESSAGE LIST</div>
      {isLoading ?
        <div className="loading-container">
          <div className="lds-dual-ring"></div>
        </div> :
        <div className="list">{Faces}</div>
      }
      <div className="close" onClick={close}>CLOSE</div>
    </BaseScene>
  );

};

export default MessageListScene;
