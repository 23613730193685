//
// リクエスト先とか文言とか
//
const currentYear = new Date().getFullYear();
export default {
  DummyAccount: 'dummy',
  NoMessage: (messageFrom) => `「${messageFrom}」からのメッセージは存在しません。`,
  VersionTitle: `${currentYear} Message book`,
  SectionChangeInterval: 1000,
  RequestUrl: {
    Login: '/api/user',
    Message: '/api/message',
    Years: '/api/years'
  },
  LoginUrl: '/login',
  ErrorMessage: {
    Request: 'サーバへのリクエストに失敗しました。',
    LoginFailure: '認証に失敗しました。',
    NotTeamlabLogin: '<a href="https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=http://messagebook.team-lab.com/">チームラボのアカウントでログインしてください。</a>',
    NoMessage: 'メッセージが登録されていません。',
    UserError: 'ユーザ情報にエラーがあります（メッセージブック担当者に連絡してください）'
  }
};
