import request from 'superagent';
import nocache from 'superagent-no-cache';
import Dispatcher from '../dispatcher.jsx';
import ActionType from '../constants/action-type-constants.jsx';
import Constants from '../constants/common-constants.jsx';
import ErrorType from '../constants/error-type-constants.jsx';

//
// メッセージ関係のアクション
//
class MessageAction {

  // メッセージを読み込む
  loadMessages(year) {
    request
      .get(Constants.RequestUrl.Message)
      .query({ year })
      .use(nocache)
      .end((err, res) => {
        if (err) {
          this.throwRequestError();
          return;
        }

        // ログインできていない場合はエラーメッセージを出すリダイレクトする
        if (!(res && res.body && res.body.result)) {
          this.throwLoginError();
          return;
        }

        setTimeout(() => {
          Dispatcher.dispatch({
            actionType: ActionType.MessageLoaded,
            messages: res.body.result.map((data) => ({
              account: data.from_mail,
              name: data.from_name,
              team: data.from_team || '',
              role: data.from_role || '',
              id: +data.from_id || 9999,
              photo: data.from_photo,
              message: data.message
            }))
          });
        }, 2000);

      });
  }

  getAvailableYears() {
    request
      .get(Constants.RequestUrl.Years)
      .use(nocache)
      .end((err, res) => {
        if (err) {
          this.throwRequestError();
          return;
        }

        setTimeout(() => {
          Dispatcher.dispatch({
            actionType: ActionType.YearsLoaded,
            years: res.body.result || []
          });
        }, 2000);

      });
  }

  // リクエスト自体が失敗した場合の処理
  throwRequestError() {
    Dispatcher.dispatch({
      actionType: ActionType.Error,
      type: ErrorType.LoginRequest,
      message: Constants.ErrorMessage.Request,
      closable: true
    });
  }

  // 認証ができていない場合の処理
  throwLoginError() {
    Dispatcher.dispatch({
      actionType: ActionType.Error,
      type: ErrorType.LoginRequest,
      message: Constants.ErrorMessage.LoginFailure,
      closable: true
    });
  }

  showMessage(messageNumber) {
    this.__showMessage(messageNumber);
  }

  __showMessage(messageNumber) {
    Dispatcher.dispatch({
      actionType: ActionType.ShowMessage,
      messageNumber
    });
  }
}

export default new MessageAction();
