//
// シーンの種類
//
export default {
  None: 'none',
  Login: 'login',
  Loading: 'loading',
  Message: 'message',
  MessageList: 'message-list'
};
