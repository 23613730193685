import Dispatcher from '../dispatcher.jsx';
import ActionType from '../constants/action-type-constants.jsx';

//
// Footerなどの表示切り替えのアクション
//
class PartsAction{

  showFooter(){
    Dispatcher.dispatch({
      actionType: ActionType.ShowFooter,
      isShow: true
    });
  }

  hideFooter(){
    Dispatcher.dispatch({
      actionType: ActionType.ShowFooter,
      isShow: false
    });
  }

  showMessageFrame(){
    Dispatcher.dispatch({
      actionType: ActionType.ShowMessageFrame,
      isShow: true
    });
  }

  hideMessageFrame(){
    Dispatcher.dispatch({
      actionType: ActionType.ShowMessageFrame,
      isShow: false
    });
  }

}

const p = window.p = new PartsAction();

export default p;
